<template>
   <div class="left-bar">
              <div class="nav-bar">
              <ul>
                <li :class="[route.name == 'home' ? 'active' : '']"><a href ="" @click.prevent="loadPage('home')"><img src="../assets/images/home-ic.svg">Home</a></li>
                <li :class="[route.name == 'sessions' ? 'active' : '']"><a href ="" @click.prevent="loadPage('sessions')"><img src="../assets/images/session.svg">Sessions</a></li>
                <li :class="[route.name == 'articles' ? 'active' : '']"><a href ="" @click.prevent="loadPage('articles')"><img src="../assets/images/artist.svg">Articles</a></li>
                <li :class="[route.name == 'my_schedules' ? 'active' : '']"> <a href ="" @click.prevent="loadPage('my_schedules')"><img src="../assets/images/wallet.svg">My Schedules</a></li>
                <li :class="[route.name == 'revenue' ? 'active' : '']"><a href ="" @click.prevent="loadPage('revenue')"><img src="../assets/images/payment.svg">Revenue</a></li>
                <li :class="[route.name == 'payouts' ? 'active' : '']"><a href ="" @click.prevent="loadPage('payouts')"><img src="../assets/images/gift.svg">Payouts</a></li>
                <li :class="[route.name == 'notifications' ? 'active' : '']"><a href ="" @click.prevent="loadPage('notifications')"><img src="../assets/images/notification.svg">Notifications</a></li>
                <li :class="[route.name == 'my_performance' ? 'active' : '']"><a href ="" @click.prevent="loadPage('my_performance')"><img src="../assets/images/fund.svg">My Performance</a></li>
                <li :class="[route.name == 'faq' ? 'active' : '']"><a href ="" @click.prevent="loadPage('faq')"><img src="../assets/images/faq.svg">FAQ</a></li>
                <li :class="[route.name == 'resources' ? 'active' : '']"><a href ="" @click.prevent="loadPage('resources')"><img src="../assets/images/artist.svg">Resources</a></li>
                <li :class="[route.name == 'terms_and_services' ? 'active' : '']"><a href ="" @click.prevent="loadPage('termservices')"><img src="../assets/images/service.svg">Terms Of Services</a></li>
                <li :class="[route.name == 'privacy' ? 'active' : '']"><a href ="" @click.prevent="loadPage('privacy')"><img src="../assets/images/privacy.svg">Privacy Policy</a></li>
                <li :class="[route.name == 'aboutus' ? 'active' : '']"><a href ="" @click.prevent="loadPage('aboutus')"><img src="../assets/images/about.svg">About Us</a></li>
                <li :class="[route.name == 'my_profile' ? 'active' : '']"><a href ="" @click.prevent="loadPage('my_profile')" ><img src="../assets/images/set.svg">My Profile</a></li>
              </ul>
            </div>
            <div class="user-account">
              <img v-if ="user" :src="user.profile_image">
              <div class="user-account-left">
                <h6>Welcome</h6>
                <h3>{{user ? user.name : ""}}</h3>
              </div>
            </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'

export default {
  data() {
        return {
              user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
           };
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    onMounted(async () => {
      await router.isReady()
    })
    window.localStorage.setItem('route', route.path);
    return { route }
  },
  methods:{
    loadPage(path){
      this.$router.push({'name':path});
    }
  }
}
</script>


