<template>
<div class="col-md-8">
   <div class="right-bar-left">
      <Transition name="slide-fade">
      <div class="shadow-block" v-if ="name_display">
         <h2>Hello, <span>{{user.name}}</span></h2>
      </div>
     </Transition>
     <ClientWaitMessage :client_wait="client_wait"/>
      <h4> <img src="../assets/images/board.svg">My Schedule <a href="/my_schedules" v-show="total_session">View All</a></h4>
      <div v-show ="session_list.length">
            <div class="shedule-time" v-for="session in session_list" :key="session.id">
               <div class="row">
                  <div class="col-md-6">
                     <div class="shedule-time-left">
                        <ul>
                           <li>{{formatDate(session.user_datetime)}}<span>Date</span></li>
                           <li>{{formatTime(session.user_start_datetime)}}<span>Time</span></li>
                           <li>{{diffTime(session.user_start_datetime,session.user_end_datetime)}}<span>Duration</span></li>
                        </ul>
                        <h6>{{ session.user_profile.name}}</h6>
                        <p>Already had {{ session.already_sessions }} sessions</p>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="shedule-time-right">
                        <ul>
                           <li>
                              <span>Gender</span>
                              {{ session.user_profile.profile.gender}}
                           </li>
                           <li>
                              <span>Symptoms</span>
                              {{session.categories_data}}
                           </li>
                        </ul>
                         <p>Notes</p>
                        <h6>{{session.user_notes}}</h6>
                     </div>
                  </div>
               </div>
            </div>
      </div>
      <div v-show ="!session_list.length">
            <div class="shedule-time" v-if = "!is_loaded">
               <div class="row">
                  <h6 style="text-align: center;">No Schedules Available...</h6>
               </div>
            </div>
            <template v-if="is_loaded">
                  <div class="nb-spinner" ></div>
            </template>
      </div>
      <h4> <img src="../assets/images/board.svg">My Resources <a href="/articles" v-if ="tips_list.length > 2">View All</a></h4>
      <template v-if ="tips_list.length">
         <div class="res-dex" v-for="(tip,index) in tips_list" :key="index">
            <h3>{{tip.question}}</h3>
            <p>{{tip.answer}}</p>
         </div>
      </template>
   </div>
</div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import moment from "moment-timezone";
import ClientWaitMessage from "./ClientWaitMessage.vue";
export default {
  props:['client_wait'],
  components:{
   ClientWaitMessage,
  },
  data() {
        return {
              user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
              total_session : 0,
              name_display:1,
              session_list : [],
              tips_list:[],
              is_loaded:true,
           };
  },
  mounted(){
      console.log("client_wait",this.client_wait);
      this.getSchedules();
      this.getTipsList();
      setTimeout(() => {
               this.name_display = 0;
            }, 2000);
  },
  methods: {
      getTipsList(){
          let getTipsList = Constant.getbaseurl()+'/tips';
          this.axios.get(getTipsList)
            .then(({data}) => {
                let response_data = data.data;
                this.tips_list = response_data ? response_data.tips : [];
            })
            .catch((error) => {
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
            });
     },
     getSchedules(){
             this.is_loaded = true;
             let my_schedules = Constant.getbaseurl()+'/therapist/web/my-schedules';
             this.axios.post(my_schedules,{"per_page":2})
               .then(({data}) => {
                  let response_data = data.data;
                  if(response_data.total != undefined){
                     this.total_session = response_data.total;
                  }
                  if(response_data.sessions != undefined){
                     this.session_list = response_data.sessions;
                  }
                  this.is_loaded = false;
                 //  Auth.login(response_data.token,response_data); //set local storage
                   //this.$router.push('/');
               })
               .catch((error) => {
                   console.log(error.response.status);
                   if(error.response.status == 401){
                       this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                       Auth.logout();
                       this.$router.push('login');
                   }else{
                       this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                   }
               });
      
     },
   formatDate(dateString) {
      return moment(dateString).format('D MMM YYYY');
   },
   formatTime(dateString) {
      return moment(dateString).format('h:mm A');
   },
   diffTime(fromString,toString) {
      var a = moment(fromString);//now
      var b = moment(toString);
      return b.diff(a, 'minutes')+" Minutes";
   },       
  }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>


