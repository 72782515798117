<template>
<div class="right-bar-right">
   <div class="switch-btn">
      <span style="text-transform: capitalize;color: green;" v-if="is_available_now">online</span>
      <span style="text-transform: capitalize;color: red;" v-if="!is_available_now">offline</span>
      <label class="switch">
      <input type="checkbox" :checked="is_available_now" @change="changeAvailabilty" v-model="is_available_now">
       <span class="slider round"></span>
      </label>
   </div>
   <div class="therap-pro">
      <div class="therap-img">
         <img v-if ="user" :src="user.profile_image">
         <span v-if="is_available_now" class="online"></span>
         <span v-if="!is_available_now" class="offline"></span>
      </div>
      <h3>{{name}}</h3>
      <p style="text-transform: capitalize;">{{user.profile.speciality}}</p>
      <a href="#" @click.prevent="getToProfile">Edit Profile</a>
   </div>
</div>
 <UserOfflineModalPage v-if="show_modal" @close = "closeModal"/>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import UserOfflineModalPage from '@/components/UserOfflineModalPage.vue'
export default {
  props:['available_status'],
  components: {
    UserOfflineModalPage
  },
  data() {
        return {
              user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
              name : "",
              available_load:true,
              is_available_now:0,
              show_modal:0,
              intial_state :0,
              date : new Date().toJSON().slice(0,10).replace(/-/g,'-'),
           };
  },
  mounted() {
       this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
       this.name = this.user ? this.user.name : null;
       this.is_available_now = this.available_status;
       this.getAvailabilty();
       this.intial_state = 0;
       console.log("new user details",this.user);
  },
  methods : {
    getAvailabilty(){
         let get_availabilty = Constant.getbaseurl()+'/therapist/check/available';
           this.axios.get(get_availabilty)
           .then(({data}) => {
                let response_data = data.data;
                this.name = response_data.user.name;
                if(this.available_load){
                  this.is_available_now = response_data.user.is_available_now;
                  this.intial_state = this.is_available_now;
                  this.$isAvailable = response_data.user.is_available_now;
                }
           })
           .catch((error) => {
              this.is_available_now = this.intial_state;
               if(error.response.status == 401){
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
           });

   },
   makeAvailable(){
        let get_availabilty = Constant.getbaseurl()+'/availability/delete_unavailability';
           this.axios.post(get_availabilty,{'date':this.date})
           .then(() => {
                //this.getAvailabilty();
                this.$swal({icon: 'success', title: "Your availability updated successfully"});
                this.$router.push('/edit_availabilty');
           })
           .catch((error) => {
               if(error.response.status == 401){
                   this.show_modal = 0;
                   this.is_available_now = this.intial_state;
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.show_modal = 0;
                   this.is_available_now = this.intial_state;
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
           });
   },
   getToProfile(){
   this.$router.push('edit_profile');
   },
   changeAvailabilty(){
      if(!this.is_available_now){
        this.available_load = false;
        this.getAvailabilty();
        this.show_modal = 1;
      }
      if(this.is_available_now){
        this.makeAvailable();
      }
      //this.intial_state;
   },
   closeModal(data){
    if(data.closer == 1){
        this.show_modal = 0;

    }
    if(data.load){
        this.$router.push('edit_availabilty');
    }else{
      this.is_available_now = this.intial_state;
    }
   }
  },
}
</script>

