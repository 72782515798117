<template>
  <div class="home">
    <HeaderPage/>
    <div class="dashboad-page">
    <div class="custom-container">
      <div class="dashboad-page-wrapper">
         <SidebarPage/>
         <div class="right-bar therapist-flow-main">
          <div class="row">
              <MainPage :client_wait="client_wait"/>
              <div class="col-md-4">
                <UserInfoPage :available_status="available_status"/>
                <CalenderPage/>
              </div>
          </div>
        </div>
        </div>
      </div>
  </div>
  <FooterPage/>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderPage from '@/components/HeaderPage.vue'
import UserInfoPage from '@/components/UserInfoPage.vue';
import CalenderPage from '@/components/CalenderPage.vue';
import SidebarPage from '@/components/SidebarPage.vue'
import MainPage from '@/components/MainPage.vue'
import FooterPage from '@/components/FooterPage.vue'

export default {
  props:['available_status','client_wait'],
  name: 'DashboardView',
  components: {
    HeaderPage,SidebarPage,MainPage,FooterPage,UserInfoPage,CalenderPage
  },
  mounted(){
     console.log("wait timeout ",this.client_wait);
     console.log("wait timeoutclient_wait_modal ",this.available_status);
  }
}
</script>
