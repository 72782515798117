<template>
<div class="modal modal-width" id ="search_request_modal" :style="{'background-color':background_color,'display':display_color}">
    <div class="modal-dialog">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body">
            <div class="session-timer-inner">
            <img src="../assets/images/Group27819.png" alt="" class="mx-auto d-block mb-3">
              
            <div class="wait-circle">
                <div class="container-custom circle-size">
                    <div class="box box-pos">
                      <div class="chart" style="margin-bottom:;"><radial-progress-bar :diameter="100" :innerStrokeColor ="innercolor" :completed-steps="percent" :total-steps="max_timeout" :startColor="startcolor" :stopColor="stopcolor" :strokeWidth="strokewidth" :isClockwise="isclockwise">
                        <span style="font-size:22px;">{{timeFromSecs(current_percent)}}<span></span></span>
                       </radial-progress-bar>
                     </div>
                    </div>
                  </div>
            </div>
            <h4 class="offline-t">You have a new user booking, are you available to Naqqe?</h4>
               
                <div class="outer-dark-grey2">
                    <p class="first-h">Username</p>
                    <p class="v-call">{{ search_modal_details.user_name }}</p>
                    <hr class="my-2">

                    <p class="first-h">{{search_modal_details.therapy}}</p>
                    <p class="v-call">Video Call </p>
                    <hr class="my-2">

                    <p class="first-h">{{search_modal_details.gender}}</p>
                    <p class="v-call">{{search_modal_details.date_of_birth}}</p>
                    <hr class="my-2">

                    <p class="first-h">
                        <span v-for="(category,key) in search_modal_details.categories" :key="key">
                            {{category}}, 
                        </span>
                        <span v-for="(sub_category,key) in search_modal_details.sub_categories" :key="key">
                            {{sub_category.name}}
                            <span v-if="key+1 < search_modal_details.sub_categories.length">, </span>
                        </span>
                    </p>
                    <p class="v-call">Symptoms </p>
                    <hr class="my-2">

                  <!--   <p class="first-h">Not taking medications</p>
                    <p class="v-call">Other Info</p> -->
                </div>
                <h5 class="his-heading" v-if="search_modal_details.last_session_details">History</h5>
                <div class="outer-date" v-if="search_modal_details.last_session_details">
                    <div class="d-flex justify-content">
                        <div class="left-date">
                            <div class="schdule-date">
                                <p class="date-name">{{ getMomentDay(search_modal_details.last_session_details.last_session_time) }}</p>
                                <p class="month-d">{{getMomentMonth(search_modal_details.last_session_details.last_session_time)}}</p>
                            </div>
                            <div class="name-schdule">
                                <h5>{{ search_modal_details.user_name }}</h5>
                                <p style="font-weight: bold;">No. of sessions: {{search_modal_details.last_session_details.total_sessions}}</p>
                            </div>
                        </div>
                        <div class="right-date">{{getMomentTime(search_modal_details.last_session_details.appointment_start_time ?? search_modal_details.last_session_details.last_session_time)}} - {{getMomentTime(search_modal_details.last_session_details.appointment_end_time)}}</div>
                    </div>
                </div>

                <div class="session-btn mt-5">
                <a href="#" class="cm-btn decline-btn" @click.prevent="hideOfflineModal()"><span>Decline</span></a>
                <a href="#" class="cm-btn" @click.prevent="saveOfflineModal()"><span>Accept</span></a>
                </div>
            </div>
        </div>

      </div>
    </div>
</div>
</template>
<script>
import RadialProgressBar from "vue3-radial-progress";
import moment from "moment-timezone";
export default {
  props:['percent','show_search_modal','display_color','search_modal_details','max_timeout','current_percent'],
  components: {
    RadialProgressBar,
  },
  data() {
        return {
               offline_by_day:false,
               offline_by_slot:true,
               current_checked_value:"offline_by_slot",
               current_available_slots:[],
               selected_slots:[],
               full_day_unavailable:null,
               main_slots_available:[],
               available_slots:[],
               unavailable_slots:[],
               offline_modal:false,
               size:90,
               background_color:"rgb(0 0 0 / 10%)",
               innercolor:"rgb(0 0 0 / 10%)",
               startcolor:"#198754",
               stopcolor:"#198754",
               strokewidth:8,
               isclockwise:true,
             //  display_color:"none",
           };
  },
  mounted(){
 
  },
  methods:{
    hideOfflineModal(){
         this.$emit('close',{'closer':1,'load':1,'type':"cancel",'data':this.search_modal_details});
    },
    saveOfflineModal(){
         this.$emit('close',{'closer':1,'load':1,'type':"save",'data':this.search_modal_details});
    },
    timeFromSecs(seconds_data)
    {
            var seconds = seconds_data; // don't forget the second param
            var hours   = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds - (hours * 3600)) / 60);
            seconds = seconds - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
           // var time    = hours+':'+minutes+':'+seconds;
            var time    = minutes+':'+seconds;
            return time;
    },
    getMomentDay(date){
     return moment(date).utc(date).local().format('D');
    },
    getMomentMonth(date){
      return moment(date).utc(date).local().format('MMM');
    },
    getMomentTime(date){
      return moment(date).utc(date).local().format('hh:mm');
    }
  }
}
</script>
<style scoped>
    .current-counter .after {
            content: "%";
    }
</style>

