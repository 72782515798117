<template>
   <header class="page-header">
    <div class="custom-container">
      <div class="row">
          <div class="col-md-6">
              <div class="header-left">
                  <div class="logo">
                    <img src="../assets/images/logo.svg">
                  </div>
                  <div class="side-nav">
                     <img src="../assets/images/side-nav.svg">
                  </div>
                  <div class="header-home">
                     <a href="#"><span><img src="../assets/images/home-ic.svg"></span></a>
                  </div>
              </div>
          </div>
          <div class="col-md-6">
              <div class="header-right">
                <div class="form-group">
                  <input type="text" name="" placeholder="Search…">
                  <img src="../assets/images/search-btn.svg">
                </div>
                <div class="notebook">
                  <a  href="#" data-bs-toggle="modal" data-bs-target="#log-out"><span><img src="../assets/images/book.svg"></span></a>
                </div>
                  <div class="notification">
                   <a href="#" @click.prevent="notification" ><span><img src="../assets/images/bell.svg"></span><label></label></a>
                </div>
              </div>
          </div>
      </div>
    </div>
  </header>
</template>
 <script>
import Auth from '../resources/Auth.js';
export default {
    methods: {
        logout() {
            Auth.logout(); //set local storage
            this.$swal({icon: 'success', title: "Logout Successfully"});
            this.$router.push('login');
        },
        notification() {
            this.$router.push('notifications');
        }
    }
}
</script>


