<template>
<div class="modal modal-width" id ="search_request_modal" :style="{'background-color':background_color,'display':display_upcoming_color}">
    <div class="modal-dialog">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body">
            <div class="session-timer-inner">
            <img src="../assets/images/Group27819.png" alt="" class="mx-auto d-block mb-3">
            <h4 class="offline-t">You have a scheduled user booking, are you available to Naqqe?</h4>
                <div class="outer-dark-grey2">
                    <p class="first-h">{{getDateFormat(upcoming_modal_details.utc_date)}}</p>
                    <p class="v-call">{{ getFormatMomentTime(upcoming_modal_details.utc_date,upcoming_modal_details.utc_start_time) }} - {{ getFormatMomentTime(upcoming_modal_details.utc_date,upcoming_modal_details.utc_end_time) }}</p>
                    <hr class="my-2">
                    <p class="first-h">Username</p>
                    <p class="v-call">{{ upcoming_modal_details.user.name }}</p>
                    <hr class="my-2">

                    <p class="first-h">{{upcoming_modal_details.therapy_type.type}}</p>
                    <p class="v-call">Video Call </p>
                    <hr class="my-2">

                    <p class="first-h">{{upcoming_modal_details.user.profile.gender}}</p>
                    <p class="v-call">{{upcoming_modal_details.user.profile.date_of_birth}}</p>
                    <hr class="my-2">

                    <p class="first-h">
                        <span v-if="upcoming_modal_details.categories">
                            {{upcoming_modal_details.categories.name}}, 
                        </span>
                        <span v-for="(sub_category,key) in upcoming_modal_details.user_selected_subcategories" :key="key">
                            {{sub_category.name}}
                            <span v-if="key+1 < upcoming_modal_details.sub_categories.length">, </span>
                        </span>
                    </p>
                    <p class="v-call">Symptoms </p>
                    <hr class="my-2">

                  <!--   <p class="first-h">Not taking medications</p>
                    <p class="v-call">Other Info</p> -->
                </div>
                <h5 class="his-heading" v-if="upcoming_modal_details.recent_session_details">History</h5>
                <div class="outer-date" v-if="upcoming_modal_details.recent_session_details">
                    <div class="d-flex justify-content">
                        <div class="left-date">
                            <div class="schdule-date">
                                <p class="date-name">{{ getMomentDay(upcoming_modal_details.recent_session_details.last_session_time) }}</p>
                                <p class="month-d">{{getMomentMonth(upcoming_modal_details.recent_session_details.last_session_time)}}</p>
                            </div>
                            <div class="name-schdule">
                                <h5>{{ upcoming_modal_details.user.name }}</h5>
                                <p style="font-weight: bold;">No. of sessions: {{upcoming_modal_details.recent_session_details.total_sessions}}</p>
                            </div>
                        </div>
                        <div class="right-date">{{getMomentTime(upcoming_modal_details.recent_session_details.appointment_start_time ?? upcoming_modal_details.recent_session_details.last_session_time)}} - {{getMomentTime(upcoming_modal_details.recent_session_details.appointment_end_time)}}</div>
                    </div>
                </div>

                <div class="session-btn mt-3">
                <a href="#" class="cm-btn decline-btn" @click.prevent="hideOfflineModal()"><span>Decline</span></a>
                <a href="#" class="cm-btn" @click.prevent="saveOfflineModal()"><span>Accept</span></a>
                </div>
            </div>
        </div>

      </div>
    </div>
</div>
</template>
<script>
import moment from "moment-timezone";
export default {
  props:['show_upcoming_modal','display_upcoming_color','upcoming_modal_details'],
  data() {
        return {
               offline_by_day:false,
               offline_by_slot:true,
               current_checked_value:"offline_by_slot",
               current_available_slots:[],
               selected_slots:[],
               full_day_unavailable:null,
               main_slots_available:[],
               available_slots:[],
               unavailable_slots:[],
               offline_modal:false,
               size:90,
               background_color:"rgb(0 0 0 / 10%)",
               innercolor:"rgb(0 0 0 / 10%)",
               startcolor:"#198754",
               stopcolor:"#198754",
               strokewidth:8,
               isclockwise:true,
             //  display_color:"none",
           };
  },
  mounted(){
 
  },
  methods:{
    hideOfflineModal(){
         this.$emit('closeUpcomingModal',{'closer':1,'load':1,'type':0,'data':this.upcoming_modal_details});
    },
    saveOfflineModal(){
         this.$emit('closeUpcomingModal',{'closer':1,'load':1,'type':1,'data':this.upcoming_modal_details});
    },
    timeFromSecs(seconds_data)
    {
            var seconds = seconds_data; // don't forget the second param
            var hours   = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds - (hours * 3600)) / 60);
            seconds = seconds - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
           // var time    = hours+':'+minutes+':'+seconds;
            var time    = minutes+':'+seconds;
            return time;
    },
    getMomentDay(date){
     return moment(date).utc(date).local().format('D');
    },
    getMomentMonth(date){
      return moment(date).utc(date).local().format('MMM');
    },
    getMomentTime(date){
      return moment(date).utc(date).local().format('hh:mm');
    },
    getFormatMomentTime(date,time){
      let datetime = moment(date).utc(date).local().format('Y-M-D')+" "+time;
      return moment(datetime).utc(datetime).local().format('hh:mm');
    },
    getDateFormat(date){
      return moment(date).utc(date).local().format('Do MMM YYYY');

    }
  }
}
</script>
<style scoped>
    .current-counter .after {
            content: "%";
    }
</style>

